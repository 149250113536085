<template>
  <li class="w-full flex justify-between border-b">
    <span>{{ name }}</span>
    <span>{{ quantity }}</span>
  </li>
</template>

<script>
export default {
  props: {
    factor: {
      type: Number,
      required: true
    },
    ingredient: {
      type: Object,
      required: true
    }
  },
  computed: {
    value () {
      if (!this.ingredient.value) return false

      const value = this.factor * this.ingredient.value

      return Number(value.toPrecision(3))
    },
    name () {
      return this.value >= 2 ? this.ingredient.namePlural : this.ingredient.nameSingular
    },
    unit () {
      return this.value >= 2 ? this.ingredient.unitPlural : this.ingredient.unitSingular
    },
    quantity () {
      if (!this.value && !this.unit?.length) return '-'
      if (!this.value) return this.unit

      return `${this.value} ${this.unit}`
    }
  }
}
</script>
