<template>
  <div v-if="recipe" class="w-full px-12 sm:px-24 pb-6 pt-20 max-w-6xl mx-auto" @click="closePreview()">
    <h1 class="text-center text-5xl font-written mt-4">{{ recipe.name }}</h1>
    <div class="text-center tracking-widest2 w-full max-w-lg mx-auto mt-2">{{ recipe.description }}</div>

    <div class="w-full flex justify-center mt-4">
      <div class="flex flex-col items-center w-16 mx-2">
        <font-awesome-icon :icon="['far', 'clock']" class="text-xl" />
        <span class="uppercase text-xs font-light mt-2">{{ recipe.prepTime + recipe.cookTime }}'</span>
      </div>
      <div v-if="saved" @click="removeBookmark" class="flex flex-col items-center w-16 cursor-pointer mx-2">
        <font-awesome-icon :icon="['fas', 'heart']" class="text-xl"/>
        <span class="uppercase text-xs font-light mt-2">Bewaard</span>
      </div>
      <div v-if="!saved" @click="addBookmark" class="flex flex-col items-center w-16 cursor-pointer mx-2">
        <font-awesome-icon :icon="['far', 'heart']" class="text-xl" />
        <span class="uppercase text-xs font-light mt-2">Bewaar</span>
      </div>
      <div @click="share" class="flex flex-col items-center w-16 cursor-pointer mx-2">
        <font-awesome-icon :icon="['fas', 'share-square']" class="text-xl" />
        <span class="uppercase text-xs font-light mt-2">{{ shared ? 'Copied!' : 'Delen'}}</span>
      </div>
    </div>

    <div class="flex flex-col-reverse sm:flex-row w-full mt-4">
      <div class="w-full flex flex-col sm:w-1/2 px-2">
        <h2 class="font-written text-xl text-center sm:text-left">Ingrediënten</h2>
        <div class="flex py-2 font-light mx-auto sm:mx-0">
          <div @click="addPerson(-1)" class="rounded-full font-bold bg-primary hover:bg-ternary text-white w-6 h-6 flex items-center justify-center cursor-pointer">-</div>
          <div class="px-2">{{ people }}</div>
          <div @click="addPerson(1)" class="rounded-full font-bold bg-primary hover:bg-ternary text-white w-6 h-6 flex items-center justify-center cursor-pointer">+</div>
          <span class="ml-2">personen</span>
        </div>

        <ul class="font-light max-w-xs">
          <Ingredient
            v-for="ingredient in recipe.recipeIngredients" :key="ingredient.id"
            :factor="people / recipe.persons"
            :ingredient="ingredient"
          />
        </ul>

        <h2 class="font-written text-xl mt-6 text-center sm:text-left">Bereidingswijze</h2>
        <ol class="font-light">
          <li
            v-for="(step, index) in recipe.steps" :key="index"
            class="list-decimal py-2 border-b"
          >
            {{ step }}
          </li>
        </ol>
      </div>

      <div class="w-full h-full sm:w-1/2 flex flex-row flex-wrap justify-start items-start">
        <div
          v-for="(image, index) in recipe.images" :key="image.id"
          class="w-1/2 p-2"
          :class="{ 'hidden sm:block': index >= 2 }"
        >
          <div
            @click.stop="openPreview(index)"
            class="cursor-pointer"
          >
            <ImageLoader
              class="w-full rounded-lg"
              :path="createImageUrl(recipe.id, image.id)"
              :alt="recipe.name"
              sizes="(min-width: 640px) 25vw, 50vw"
              :max="image.originalWidth"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="fixed z-60 inset-0 w-full h-full bg-black bg-opacity-70"
      v-if="preview"
    >
      <div
        class="sticky w-full h-screen flex justify-center items-center"
      >
        <div
          class="text-white text-xl md:text-3xl px-2 py-4 cursor-pointer"
          @click.stop="prevPreview()"
          >
          <font-awesome-icon :icon="['fas', 'chevron-left']" />
        </div>

        <ImageLoader
          class="rounded-lg"
          :path="this.createImageUrl(recipe.id, preview.id)"
          :alt="recipe.name"
          sizes="
            (min-width: 1536px) 30vw,
            (min-width: 1280px) 40vw,
            (min-width: 1024px) 45vw,
            (min-width: 768px) 50vw,
            75vw"
          :max="2048"
        />

        <div
          class="text-white text-xl md:text-3xl px-2 py-4 cursor-pointer"
          @click.stop="nextPreview()"
        >
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '../http'
import store, { addFavorite, removeFavorite } from '../store'
import { sendEvent } from '../mixins/analytics'

import ImageLoader from '@/components/ImageLoader'
import Ingredient from '@/components/Ingredient'

export default {
  components: {
    ImageLoader,
    Ingredient
  },
  data () {
    return {
      people: 4,
      recipe: null,
      shared: false,
      previewIndex: null
    }
  },
  async mounted () {
    await this.getRecipe()

    this.insertStructuredData()

    sendEvent('recipeview', this.recipe.id, this.$route.fullPath)
  },
  computed: {
    saved () {
      return store.favorites.includes(this.recipe?.id)
    },
    preview () {
      return this.recipe.images[this.previewIndex]
    }
  },
  methods: {
    async getRecipe () {
      try {
        const res = await http.get(`/api/recipe-slugs/${this.$route.params.slug}`)

        this.recipe = res.data

        this.people = res.data.persons
      } catch (e) {
        this.$router.push({ path: '/' })
      }
    },
    insertStructuredData () {
      document.title = this.recipe.name

      if (store.structuredData) return

      const script = document.createElement('script')
      script.setAttribute('type', 'application/ld+json')
      script.textContent = structuredData(this.recipe)
      document.head.appendChild(script)

      store.structuredData = true
    },
    addPerson (amount) {
      this.people += amount

      if (this.people <= 0) this.people = 1
    },
    createImageUrl (recipeId, imageId) {
      return `/api/recipes/${recipeId}/images/${imageId}`
    },
    openPreview (index) {
      this.previewIndex = index
    },
    closePreview () {
      this.previewIndex = null
    },
    nextPreview () {
      this.previewIndex += 1

      if (this.previewIndex >= this.recipe.images.length) {
        this.previewIndex = 0
      }
    },
    prevPreview () {
      this.previewIndex -= 1

      if (this.previewIndex < 0) {
        this.previewIndex = this.recipe.images.length - 1
      }
    },
    addBookmark () {
      addFavorite(this.recipe, this.$route.fullPath)
    },
    removeBookmark () {
      removeFavorite(this.recipe, this.$route.fullPath)
    },
    share () {
      navigator.clipboard.writeText(location.href).then(() => {
        this.shared = true
        setTimeout(() => {
          this.shared = false
        }, 1000)
      })

      sendEvent('share', this.recipe.id, this.$route.fullPath)
    }
  }
}

function structuredData (recipe) {
  const image = recipe?.preview ?? recipe.images[0]

  return JSON.stringify({
    '@context': 'https://schema.org/',
    '@type': 'Recipe',
    name: recipe.name,
    image: [
      `${process.env.VUE_APP_API_CF}/api/recipes/${recipe.id}/images/${image.id}`
    ],
    author: {
      '@type': 'Person',
      name: 'Katrijn Droogmans'
    },
    datePublished: recipe.createdAt.split('T')[0],
    description: recipe.description,
    recipeIngredient: recipe.recipeIngredients.map(ingredient => {
      const value = ingredient.value

      const name = value >= 2 ? ingredient.namePlural : ingredient.nameSingular
      const unit = value >= 2 ? ingredient.unitPlural : ingredient.unitSingular

      return `${value ?? ''} ${unit ?? ''} ${name}`
    }),
    cookTime: `PT${recipe.cookTime}M`,
    prepTime: `PT${recipe.prepTime}M`,
    totalTime: `PT${recipe.prepTime + recipe.cookTime}M`,
    recipeInstructions: recipe.steps.map(step => {
      return { '@type': 'HowToStep', text: step }
    }),
    recipeYield: recipe.persons
  }, null, 2)
}

</script>
